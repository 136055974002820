@import "tailwindcss/base";

@layer base {

    h1,
    .h1 {
        font-size: clamp(46px, 4vw, 64px);
        line-height: 1.3;
        color: #333;
        font-family: theme("typography.fontFamily.regular");
        font-weight: theme("typography.fontWeight.regular");
    }

    h2,
    .h2 {
        font-size: 56px;
        line-height: 1.3;
        color: #333;
        font-family: theme("typography.fontFamily.regular");
        font-weight: theme("typography.fontWeight.regular");
    }

    h3,
    .h3 {
        font-size: 46px;
        line-height: 1.3;
        color: #333333;
        font-family: theme("typography.fontFamily.regular");
        font-weight: theme("typography.fontWeight.regular");
    }

    h4,
    .h4 {
        font-size: 22px;
        font-family: theme("typography.fontFamily.regular");
        font-weight: theme("typography.fontWeight.regular");
        color: #333333;
    }

    h5,
    .h5 {
        font-size: 18px;
        font-family: theme("typography.fontFamily.regular");
        font-weight: theme("typography.fontWeight.thin");
        color: #333333;
    }

    h6,
    .h6 {
        font-size: 14px;
        font-family: theme("typography.fontFamily.regular");
        font-weight: theme("typography.fontWeight.regular");
        color: #333333;
    }

    .preamble {
        font-size: 24px;
        font-family: theme("typography.fontFamily.regular");
        font-weight: theme("typography.fontWeight.thin");
        color: #666;
    }

    p,
    .p {
        font-size: 20px;
        font-family: theme("typography.fontFamily.regular");
        font-weight: theme("typography.fontWeight.thin");
        color: #666;
    }

    .paragraph {
        font-size: 16px;
        font-family: theme("typography.fontFamily.regular");
        font-weight: theme("typography.fontWeight.thin");
        color: #666;
    }


    .paragraph-small {
        font-size: 14px;
        font-family: theme("typography.fontFamily.regular");
        font-weight: theme("typography.fontWeight.thin");
        color: #666;
    }

    .paragraph-xsmall {
        font-size: 12px;
        font-family: theme("typography.fontFamily.regular");
        font-weight: theme("typography.fontWeight.thin");
        color: #666;
    }

    .label {
        font-size: 12px;
        font-family: theme("typography.fontFamily.regular");
        font-weight: theme("typography.fontWeight.thin");
        font-style: normal;
        color: #333;
    }

    a {
        color: #333;
        transition: color 300ms ease-out;
    }

    a:hover {
        color: theme("colors.theme")
    }

    svg {
        color: #333;
    }

    svg:hover {
        color: #333;
    }
}